import React, { useCallback } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import {
  GET_ORGANISATION_ENVIRONMENT,
  SET_ALLOWED_ELEMENT_PATHS,
  SET_ORGANISATION_ENVIRONMENT_NOTES,
} from 'admin-client/app/api/gql/queries';
import { NotesPanel } from 'admin-client/app/components/Notes/NotesPanel';
import { AddAllowedElementPathPanel } from 'admin-client/app/components/OrganisationEnvironments/AddAllowedElementPathPanel';
import { SupportConnection } from 'admin-client/app/components/OrganisationEnvironments/SupportConnection';
import { HasPermission, usePermissions } from 'admin-client/app/components/Permissions';
import { getOrganisationEnvironmentQuery } from 'admin-client/app/gql';
import { OrganizationEnvDeployParams } from 'admin-common/src/routing/routes';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { Stack } from 'common/ui/components/Stack';

export default function OrganisationEnvironmentDetailsContainer() {
  const { orgHumanIdentifier, envHostname } = useParams<OrganizationEnvDeployParams>();

  const orgEnvQuery = useQuery(GET_ORGANISATION_ENVIRONMENT, {
    variables: {
      orgHumanIdentifier,
      envHostname,
    },
  });

  if (orgEnvQuery.loading) {
    return <div>Loading...</div>;
  }
  if (orgEnvQuery.error) {
    return <div>Error! {orgEnvQuery.error.message}</div>;
  }
  if (!orgEnvQuery.data) {
    return <div>Error! Organisation environment data is undefined</div>;
  }

  return (
    <OrganisationEnvironmentDetails
      orgEnv={orgEnvQuery.data.organisationEnvironment}
      supportConnection={orgEnvQuery.data.supportConnection}
    />
  );
}

type OrganisationEnvironmentDetailsProps = {
  orgEnv: getOrganisationEnvironmentQuery['organisationEnvironment'];
  supportConnection: getOrganisationEnvironmentQuery['supportConnection'];
};

const OrganisationEnvironmentDetails = React.memo(
  (props: OrganisationEnvironmentDetailsProps) => {
    const { orgEnv, supportConnection } = props;
    const snackbarManager = useSnackbarManager();
    const { currentUserHasPermission } = usePermissions();

    const commonMutationOptions = {
      onError: (error: ApolloError) => {
        console.error(error);
        snackbarManager.showError(error.message);
      },
    };
    const [setAllowedElementPaths] = useMutation(
      SET_ALLOWED_ELEMENT_PATHS,
      commonMutationOptions,
    );
    const [setNotesMutation] = useMutation(
      SET_ORGANISATION_ENVIRONMENT_NOTES,
      commonMutationOptions,
    );

    const handleAddAllowedElementPath = useCallback(
      async (allowedElementPath: string) => {
        const allowedElementPaths = Array.from(
          new Set([...orgEnv.allowedElementPaths, allowedElementPath]),
        );
        await setAllowedElementPaths({
          variables: {
            organisationId: orgEnv.organisation.id,
            environmentId: orgEnv.environment.id,
            allowedElementPaths,
          },
          refetchQueries: [
            {
              query: GET_ORGANISATION_ENVIRONMENT,
              variables: {
                orgHumanIdentifier: orgEnv.organisation.humanIdentifier,
                envHostname: orgEnv.environment.hostname,
              },
            },
          ],
        });
      },
      [
        orgEnv.allowedElementPaths,
        orgEnv.environment.hostname,
        orgEnv.environment.id,
        orgEnv.organisation.humanIdentifier,
        orgEnv.organisation.id,
        setAllowedElementPaths,
      ],
    );

    const handleNotesUpdate = useCallback(
      async (notes: string) => {
        await setNotesMutation({
          variables: {
            organisationId: orgEnv.organisation.id,
            environmentId: orgEnv.environment.id,
            notes,
          },
          refetchQueries: [
            {
              query: GET_ORGANISATION_ENVIRONMENT,
              variables: {
                orgHumanIdentifier: orgEnv.organisation.humanIdentifier,
                envHostname: orgEnv.environment.hostname,
              },
            },
          ],
        });
      },
      [
        orgEnv.environment.hostname,
        orgEnv.environment.id,
        orgEnv.organisation.humanIdentifier,
        orgEnv.organisation.id,
        setNotesMutation,
      ],
    );

    return (
      <Stack spacing={6}>
        <section>
          <Typography variant="h2" gutterBottom>
            {orgEnv.organisation.name} on {orgEnv.environment.hostname}
          </Typography>
          <NotesPanel
            notes={orgEnv.notes}
            onUpdate={handleNotesUpdate}
            editable={currentUserHasPermission('update:organisations')}
          />
        </section>
        <SupportConnection orgEnv={orgEnv} supportConnection={supportConnection} />
        <section>
          <Typography variant="h4" gutterBottom>
            Allowed Element Paths
          </Typography>
          <ul>
            {[...orgEnv.allowedElementPaths].sort().map(path => (
              <li key={path}>
                <Typography>{path}</Typography>
              </li>
            ))}
          </ul>
        </section>
        <section>
          <Typography variant="h5" gutterBottom>
            Add Allowed Element Path
          </Typography>
          <HasPermission
            permission="update:organisations"
            renderItem={hasPermission => {
              return hasPermission ? (
                <AddAllowedElementPathPanel
                  env={orgEnv.environment}
                  addAllowedElementPathCallback={handleAddAllowedElementPath}
                />
              ) : null;
            }}
          />
        </section>
      </Stack>
    );
  },
);
