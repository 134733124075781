import React, { useCallback, useMemo, useState } from 'react';

import BasePlateContentsEditorDialog, {
  PlateOption,
} from 'client/app/components/Parameters/PlateContents/BasePlateContentsEditorDialog';
import { EMPTY_WELL_GROUP_ID } from 'client/app/components/Parameters/PlateContents/BasePlateContentsEditorWellGroupList';
import { WellParametersProps } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import RoboColumnParameters from 'client/app/components/Parameters/RoboColumnLayout/RoboColumnParameters';
import { parseMeasurement } from 'common/lib/format';
import createDummyPlate from 'common/types/dummyPlates';
import { WellContents } from 'common/types/mix';
import { RoboColumnContent } from 'common/types/robocolumns';
import Colors from 'common/ui/Colors';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  value: Record<string, RoboColumnContent> | undefined;
} & DialogProps<Record<string, RoboColumnContent> | undefined>;

const PLATE_OPTIONS: PlateOption[] = [
  {
    label: 'Short RoboColumns',
    plate: createDummyPlate('96 wells'),
  },
  {
    label: 'Long RoboColumns',
    plate: createDummyPlate('96 wells'),
  },
];

export default function RoboColumnLayoutDialog({
  isOpen,
  onClose,
  value,
  isDisabled,
}: Props) {
  const [plate, setPlate] = useState<PlateOption>(PLATE_OPTIONS[0]);

  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);

  const generateWellInfo = useCallback((robocolumn: RoboColumnContent): WellContents => {
    const volume = parseMeasurement(robocolumn.Volume || '');
    // Remove the unit so that the number will be larger within the well
    const volumeWithoutUnit = { value: volume?.value || 0, unit: '' };
    return {
      // TODO: change this to filter_matrix_summary once patterns are implemented T2667
      kind: 'liquid_summary',
      id: '0',
      name: robocolumn.Resin,
      total_volume: volumeWithoutUnit,
    };
  }, []);

  const robocolumnParameters = useCallback(
    (editorProps: WellParametersProps<RoboColumnContent>) => (
      <RoboColumnParameters {...editorProps} roboColumnType={plate.label} />
    ),
    [plate],
  );

  const groupID = useCallback(
    (content: RoboColumnContent | undefined) =>
      content ? `${content.Resin} ${content.Volume}` : EMPTY_WELL_GROUP_ID,
    [],
  );

  const groupProps = useCallback(
    (content: RoboColumnContent | undefined) => {
      const amount = parseMeasurement(content?.Volume || '');
      return {
        title: content
          ? `${content.Resin} ${amount?.value || ''} ${amount?.unit || ''}`
          : '[empty]',
        color: content
          ? liquidColors.getColorFromLiquidString(content.Resin || '', false)
          : Colors.WHITE,
      };
    },
    [liquidColors],
  );

  /**
   * Each new RoboColumn should have the name "RoboColumn X", where X is an
   * unused number.
   */
  const generateInitialRoboColumns = useCallback(
    (
      wellLocations: string[],
      wellContents: Map<string, RoboColumnContent>,
      roboColumnToCopy: RoboColumnContent = {},
    ): Map<string, RoboColumnContent> => {
      const existingNames = new Set(
        [...wellContents.values()].map(robocolumn => robocolumn.RoboColumn),
      );
      let roboColumnNumber = 0;
      return new Map(
        wellLocations.map(wellLocation => {
          let name;
          do {
            roboColumnNumber++;
            name = 'RoboColumn ' + roboColumnNumber;
          } while (existingNames.has(name));
          return [wellLocation, { ...roboColumnToCopy, RoboColumn: name }];
        }),
      );
    },
    [],
  );

  return (
    <BasePlateContentsEditorDialog
      dialogTitle="RoboColumn Layout"
      helpText="Click on plate locations where RoboColumns are located. You can select an entire row or column by clicking on the corresponding label. Drag to select a group."
      wellParameters={robocolumnParameters}
      isOpen={isOpen}
      availablePlates={PLATE_OPTIONS}
      selectedPlate={plate}
      value={value}
      onPlateChange={setPlate}
      onClose={onClose}
      liquidColors={liquidColors}
      showWellLabels
      isDisabled={isDisabled}
      groupID={groupID}
      groupProps={groupProps}
      generateWellContents={generateInitialRoboColumns}
      generateWellInfo={generateWellInfo}
    />
  );
}
