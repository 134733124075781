import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { GET_ACTIONS, PUBLISH_TUTORIALS } from 'admin-client/app/api/gql/queries';
import { pluralizeWord } from 'common/lib/format';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export const usePublishTutorials = () => {
  const snackbarManager = useSnackbarManager();

  const [publishTutorials, { loading, client }] = useMutation(PUBLISH_TUTORIALS, {
    onError: async error => {
      snackbarManager.showError(error.message);
      await client.refetchQueries({ include: [GET_ACTIONS] });
    },

    onCompleted: async ({ publishTutorials: { successEnvs, failureEnvs } }) => {
      await client.refetchQueries({ include: [GET_ACTIONS] });

      if (failureEnvs && failureEnvs.length > 0) {
        snackbarManager.showWarning(
          `Publishing tutorials failed for ${failureEnvs.length} ${pluralizeWord(
            failureEnvs.length,
            'environment',
          )}`,
        );
      } else {
        snackbarManager.showSuccess(
          `Successfully published tutorials to ${successEnvs.length}
          ${pluralizeWord(successEnvs.length, 'environment')}`,
        );
      }
    },
  });

  const onClick = useCallback(
    (selectedTargetEnvironments: any) => async () => {
      await publishTutorials({
        variables: selectedTargetEnvironments
          ? {
              targetEnvironmentBaseURLs: selectedTargetEnvironments,
            }
          : undefined,
      });
    },
    [publishTutorials],
  );

  return { loading, onClick };
};
