import levenshtein from 'js-levenshtein';
import munkres from 'munkres-js';

import { TableColumn } from 'common/types/spreadsheetEditor';

/** Compute a distance matrix based on levenshtein distance (distance between
 * two strings). Used to get distance from users' csv headers and expected headers */
function getDistanceMatrix(parsedHeaders: string[], expectedHeaders: string[]) {
  const matrix: number[][] = parsedHeaders.map(parsedHeader =>
    expectedHeaders.map(expectedHeader =>
      levenshtein(parsedHeader.toLocaleLowerCase(), expectedHeader.toLocaleLowerCase()),
    ),
  );
  return matrix;
}

/** A `{parsedHeader: expectedHeader}` map. */
export type MappedHeaders = { [parsedHeader: string]: string };
export type MappedHeadersBySheet = { [sheetIndex: number]: MappedHeaders };
export type ExtraColumnsBySheet = { [sheetIndex: number]: TableColumn[] };

/**
 * Given users specified headers and expected headers, return a map
 * `{ parsedHeader: bestGuessOfExpectedHeader }`
 * In other words, map users' file headers automatically to the closest expected header.
 * E.g. "source_plate_name" will be matched to the expected "Source Plate Name"
 */
export function getAutomappedHeaders(
  parsedHeaders: string[],
  expectedHeaders: string[],
  options?: {
    // If levenshtein is less than this threshold, header will be automatically selected
    threshold?: number;
    defaultValue?: string;
  },
): [MappedHeaders, number] {
  const { threshold = 3, defaultValue = '' } = { ...options };

  const distanceMatrix = getDistanceMatrix(parsedHeaders, expectedHeaders);
  // Use the hungarian (munkres) algorithm to compute the most similar headers
  const similarityResultsMatrix = munkres(distanceMatrix);
  // If the total distance is 0, we can auto-map headers without users input
  let totalDistance = 0;
  const mappedHeaders: MappedHeaders = parsedHeaders.reduce((acc, header) => {
    acc[header] = defaultValue;
    return acc;
  }, {} as MappedHeaders);

  const pairsWithinThreshold = Object.values(similarityResultsMatrix).filter(
    ([parsedHeaderIndex, expectedHeaderIndex]) =>
      distanceMatrix[parsedHeaderIndex][expectedHeaderIndex] < threshold,
  );

  pairsWithinThreshold.forEach(([parsedHeaderIndex, expectedHeaderIndex]) => {
    const parsedHeader = parsedHeaders[parsedHeaderIndex];
    const expectedHeader = expectedHeaders[expectedHeaderIndex];
    mappedHeaders[parsedHeader] = expectedHeader;
    totalDistance += distanceMatrix[parsedHeaderIndex][expectedHeaderIndex];
  });

  return [mappedHeaders, totalDistance];
}

export function getHeadersFromColumns(columns: TableColumn[]) {
  return columns.map(column => column.name);
}

export function hasParsedMoreSheetsThanExpected(
  parsedSheets: string[][],
  expectedSheets: string[][],
) {
  for (const [sheetIndex, expectedSheet] of expectedSheets.entries()) {
    if (expectedSheet.length !== parsedSheets[sheetIndex].length) {
      return true;
    }
  }
  return false;
}
