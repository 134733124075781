import React, { useCallback } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';

import {
  GET_ENVIRONMENT,
  SET_ENVIRONMENT_ANTHA_HUB_RELEASE_VERSION,
  SET_ENVIRONMENT_DEVICE_DRIVER_RELEASE_VERSION,
  SET_ENVIRONMENT_NOTES,
} from 'admin-client/app/api/gql/queries';
import { DeleteEnvironment } from 'admin-client/app/components/Environments/DeleteEnvironment';
import { ReleasePickerDialog } from 'admin-client/app/components/Environments/ReleasePickerDialog';
import { InfoBox } from 'admin-client/app/components/InfoBox';
import { NotesPanel } from 'admin-client/app/components/Notes/NotesPanel';
import { OrganisationEnvironmentLink } from 'admin-client/app/components/OrganisationEnvironments/OrganisationEnvironmentLink';
import { OrganisationLink } from 'admin-client/app/components/Organisations/OrganisationLink';
import { usePermissions } from 'admin-client/app/components/Permissions';
import { getEnvironmentQuery as GraphQLEnvironment } from 'admin-client/app/gql';
import { AnthaHubInstallersProjectName } from 'admin-common/src/constants';
import { EnvironmentParams } from 'admin-common/src/routing/routes';
import { useDialogManager } from 'common/ui/components/DialogManager';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { EditIcon } from 'common/ui/icons/Edit';

type GraphQLOrganisationEnvironment = GraphQLEnvironment['environment'];

export default function EnvironmentDetailsContainer() {
  const { hostname } = useParams<EnvironmentParams>();

  const env = useQuery(GET_ENVIRONMENT, {
    variables: { hostname },
  });

  if (env.loading) {
    return <div>Loading...</div>;
  }
  if (env.error) {
    return <div>Error! {env.error.message}</div>;
  }
  if (!env.data) {
    return <div>Error! Environment data is undefined</div>;
  }

  return <EnvironmentDetails env={env.data.environment} />;
}

type EnvironmentDetailsProps = {
  env: GraphQLOrganisationEnvironment;
};

const AUTO_UPDATE_DISABLED_LABEL = 'auto-update disabled';

const EnvironmentDetails = React.memo((props: EnvironmentDetailsProps) => {
  const { env } = props;
  const classes = useStyles();
  const dialogManager = useDialogManager();
  const snackbarManager = useSnackbarManager();
  const { currentUserHasPermission } = usePermissions();

  const commonMutationOptions = {
    onError: (error: ApolloError) => {
      console.error(error);
      snackbarManager.showError(error.message);
    },
  };

  const [setAnthaHubReleaseVersion] = useMutation(
    SET_ENVIRONMENT_ANTHA_HUB_RELEASE_VERSION,
    commonMutationOptions,
  );

  const [setDeviceDriverReleaseVersion] = useMutation(
    SET_ENVIRONMENT_DEVICE_DRIVER_RELEASE_VERSION,
    commonMutationOptions,
  );
  const [setNotesMutation] = useMutation(SET_ENVIRONMENT_NOTES, commonMutationOptions);

  const orgs = env.enabledOrganisationEnvironments
    .map(oe => oe.organisation)
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleSetReleaseVersionClick = useCallback(
    (projectName: string, currentReleaseVersion: string) => async () => {
      const newReleaseVersion = await dialogManager.openDialogPromise(
        'SET_ENVIRONMENT_ANTHA_HUB_RELEASE_VERSION',
        ReleasePickerDialog,
        {
          currentReleaseVersion,
          projectName,
        },
      );
      if (newReleaseVersion && newReleaseVersion !== currentReleaseVersion) {
        const refetchQueries = [
          {
            query: GET_ENVIRONMENT,
            variables: { hostname: env.hostname },
          },
        ];
        if (projectName === AnthaHubInstallersProjectName) {
          await setAnthaHubReleaseVersion({
            variables: {
              environmentId: env.id,
              anthaHubReleaseVersion: newReleaseVersion,
            },
            refetchQueries,
          });
        } else {
          await setDeviceDriverReleaseVersion({
            variables: {
              environmentId: env.id,
              deviceClass: projectName,
              deviceDriverReleaseVersion: newReleaseVersion,
            },
            refetchQueries,
          });
        }
      }
    },
    [
      dialogManager,
      env.hostname,
      env.id,
      setAnthaHubReleaseVersion,
      setDeviceDriverReleaseVersion,
    ],
  );

  const handleNotesUpdate = useCallback(
    async (notes: string) => {
      await setNotesMutation({
        variables: {
          environmentId: env.id,
          notes,
        },
        refetchQueries: [
          {
            query: GET_ENVIRONMENT,
            variables: { hostname: env.hostname },
          },
        ],
      });
    },
    [env.hostname, env.id, setNotesMutation],
  );

  return (
    <div>
      <section className={classes.section}>
        <Typography variant="h2">{env.hostname}</Typography>
        {env.additionalURLs.map(url => (
          <Typography key={url} variant="subtitle2" className={classes.envId}>
            <a href={url}>{url}</a>
          </Typography>
        ))}
        <Typography variant="subtitle2" className={classes.envId}>
          {env.id}
        </Typography>
        <NotesPanel
          notes={env.notes}
          onUpdate={handleNotesUpdate}
          editable={currentUserHasPermission('update:environments')}
        />
      </section>
      <section className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Enabled organisations
        </Typography>
        {orgs.length > 0 ? (
          <ul>
            {orgs.map(org => (
              <li key={org.id}>
                <Typography>
                  <OrganisationLink org={org} /> (
                  <OrganisationEnvironmentLink org={org} env={env} />)
                </Typography>
              </li>
            ))}
          </ul>
        ) : (
          <Typography variant="body1">None</Typography>
        )}
      </section>
      <section className={classes.section}>
        <Typography variant="h4" gutterBottom>
          SynthaceHub release versions
        </Typography>
        <InfoBox>
          Note:
          <ul>
            <li>
              Only SynthaceHub{' '}
              <a href="https://github.com/Synthace/AnthaHub/releases/tag/anthahub/1.0.4239.0">
                1.0.4239.0
              </a>{' '}
              and above fetches SynthaceHub installer updates from the Admin Tool.{' '}
            </li>
            <li>
              Only SynthaceHub{' '}
              <a href="https://github.com/Synthace/AnthaHub/releases/tag/anthahub/1.0.4285.0">
                1.0.4285.0
              </a>{' '}
              and above fetches device plugin updates from the Admin Tool.
            </li>
          </ul>
          <a href="https://www.notion.so/synthace/How-AnthaHub-releases-work-98d4a59b2f654ccba821b33df7750e77">
            Learn more
          </a>
        </InfoBox>
        <Table size="small" className={classes.versionTable}>
          <TableBody>
            <TableRow>
              <TableCell>SynthaceHub Installer</TableCell>
              <TableCell>
                {env.anthaHubAutoUpdateEnabled
                  ? env.anthaHubReleaseVersion
                  : AUTO_UPDATE_DISABLED_LABEL}
                <IconButton
                  size="small"
                  color="primary"
                  onClick={handleSetReleaseVersionClick(
                    AnthaHubInstallersProjectName,
                    env.anthaHubReleaseVersion,
                  )}
                >
                  <EditIcon className={classes.editIcon} />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.inlineHeaderRow}>Device Plugins</TableCell>
              <TableCell />
            </TableRow>
            {env.deviceDriverVersions.map(ddv => (
              <TableRow key={ddv.deviceClass}>
                <TableCell>{ddv.deviceClass}</TableCell>
                <TableCell>
                  {ddv.autoUpdateEnabled ? ddv.version : AUTO_UPDATE_DISABLED_LABEL}
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={handleSetReleaseVersionClick(ddv.deviceClass, ddv.version)}
                  >
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
      <DeleteEnvironment environment={env} mutationOptions={commonMutationOptions} />
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      paddingBottom: '2rem',
    },
    envId: {
      color: theme.palette.text.secondary,
    },
    versionTable: {
      display: 'flow',
    },
    inlineHeaderRow: {
      fontWeight: 'bold',
    },
    editIcon: {
      fontSize: '0.85em',
    },
  }),
);
