import React, { useCallback, useEffect, useState } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import omit from 'lodash/omit';

import useDebounce from 'common/ui/hooks/useDebounce';

type Props = {
  delay?: number;

  // You might expect that we'd get back a string, but apparently we can
  // get back all sorts of exciting things. Meh.
  onDebouncedChange: (value: any) => void;
} & TextFieldProps;

const DEFAULT_DELAY_MS = 500;

function DebouncedTextField(props: Props) {
  const {
    delay = DEFAULT_DELAY_MS,
    onChange: onChangeFromProps,
    value: valueFromProps,
    onDebouncedChange,
  } = props;

  const [value, setValue] = useState(valueFromProps);

  const debouncedOnValueChange = useDebounce(onDebouncedChange, delay);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      setValue(v);
      debouncedOnValueChange(v);

      // Still allow the user to specify the native onchange
      if (onChangeFromProps) {
        onChangeFromProps(e);
      }
    },
    [debouncedOnValueChange, onChangeFromProps],
  );

  useEffect(() => {
    setValue(valueFromProps);
  }, [valueFromProps, setValue]);

  const xferProps = omit(
    props,
    'delay',
    'value',
    'onDebouncedChange',
    'onChange',
  ) as TextFieldProps;
  return <TextField {...xferProps} value={value} onChange={onChange} />;
}

export default DebouncedTextField;
