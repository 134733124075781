import React, { useCallback, useMemo, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

import { CherryPickPlateRole } from 'client/app/apps/cherry-picker/cp-plate-vis/CherryPickPlatesView';
import { ScreenRegistry } from 'client/app/registry';
import Button from 'common/ui/components/Button';
import Dropdown from 'common/ui/filaments/Dropdown';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type PlateToAdd = { newPlateName: string; connectedPlateName: string };
type AddPlateDialogProps = {
  plateRole: CherryPickPlateRole;
  allPlates: string[];
} & DialogProps<PlateToAdd | null>;

function AddPlateDialog(props: AddPlateDialogProps) {
  const classes = useStyles();
  const { plateRole, allPlates, onClose, isOpen } = props;
  const [plateName, setPlateName] = useState('');
  const [selectedPlate, setSelectedPlate] = useState('');
  const [error, setError] = useState('');

  const onChangePlateName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const plateName = e.target.value;
    if (plateName) {
      setError('');
      setPlateName(plateName);
    }
  }, []);

  const dropdownLabel = useMemo(
    () => (plateRole === 'Source' ? 'Transfers to: ' : 'Transfers from: '),
    [plateRole],
  );

  const handleSelectPlate = useCallback((selectedPlate?: string) => {
    logEvent('select-plate-from-dropdown', ScreenRegistry.CHERRY_PICKER);
    setSelectedPlate(selectedPlate ?? '');
  }, []);

  const handleAddNewPlate = useCallback(() => {
    logEvent('add-new-plate', ScreenRegistry.CHERRY_PICKER);
    if (!plateName) {
      setError('Please enter a name for the plate');
      return;
    }
    onClose({ newPlateName: plateName, connectedPlateName: selectedPlate });
  }, [onClose, plateName, selectedPlate]);

  const onCancel = useCallback(() => onClose(null), [onClose]);

  const dropdownOptions = useMemo(
    () => allPlates.map(plate => ({ label: plate, value: plate })),
    [allPlates],
  );

  return (
    <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle>{`Add ${plateRole.toLocaleLowerCase()} plate`}</DialogTitle>
      <DialogContent>
        Please enter a name for the new plate and select which plate it interacts with.
        <div className={classes.plateControls}>
          <TextField
            fullWidth
            placeholder={`my${plateRole}Plate`}
            value={plateName}
            onChange={onChangePlateName}
            margin="normal"
            error={!!error}
            helperText={error}
          />
          <InputLabel shrink>{dropdownLabel}</InputLabel>
          <Dropdown
            options={dropdownOptions}
            valueLabel={selectedPlate}
            onChange={handleSelectPlate}
            placeholder="Itself"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="tertiary" onClick={handleAddNewPlate} color="primary">
          Add plate
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  plateControls: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default AddPlateDialog;
