import produce from 'immer';

import { ContentSourceType } from 'common/types/contentSource';
import {
  ContainerType,
  PlateType,
  WellBottomShapeEnum,
  WellShapeEnum,
} from 'common/types/plateType';
import { Position3d } from 'common/types/Position';

//  Changing just rows and columns is not enough to have a nice looking
//  plate displayed in the UI. The values below are taken from  plates
//  we have in our Inventory.
// TODO(T4496): Factor the constants here into a shared enum
export const COMMON_PLATE_DIMENSIONS = {
  '6 wells': {
    rows: 2,
    columns: 3,
    wellDimension: { x: 37, y: 37, z: 20 },
    wellOffset: { x: 39, y: 39 },
    wellStart: { x: 24.16, y: 22.76, z: 9.7 },
    wellShapeType: WellShapeEnum.CIRCLE,
  },
  '12 wells': {
    rows: 3,
    columns: 4,
    wellDimension: { x: 22.5, y: 22.5, z: 17 },
    wellOffset: { x: 27, y: 27 },
    wellStart: { x: 25.28, y: 15.24, z: 4.7 },
    wellShapeType: WellShapeEnum.CIRCLE,
  },
  '12 wells (trough)': {
    rows: 1,
    columns: 12,
    wellDimension: { x: 8.2, y: 72, z: 41.3 },
    wellOffset: { x: 9, y: 9 },
    wellStart: { x: 14.28, y: 41.24, z: 5.2 },
    wellShapeType: WellShapeEnum.RECTANGLE,
  },
  '24 wells': {
    rows: 4,
    columns: 6,
    wellDimension: { x: 15.62, y: 15.62, z: 17.4 },
    wellOffset: { x: 19.3, y: 19.3 },
    wellStart: { x: 17.46, y: 13.78, z: 2.54 },
    wellShapeType: WellShapeEnum.CIRCLE,
  },
  '48 wells': {
    rows: 6,
    columns: 8,
    wellDimension: { x: 11, y: 11, z: 19 },
    wellOffset: { x: 13, y: 13 },
    wellStart: { x: 17.28, y: 10.24, z: 2.7 },
    wellShapeType: WellShapeEnum.CIRCLE,
  },
  '96 wells': {
    rows: 8,
    columns: 12,
    wellDimension: { x: 7.1, y: 7.1, z: 10.2 },
    wellOffset: { x: 9, y: 9 },
    wellStart: { x: 13.78, y: 11.24, z: 3.7 },
    wellShapeType: WellShapeEnum.CIRCLE,
  },
  '384 wells': {
    rows: 16,
    columns: 24,
    wellDimension: { x: 3.7, y: 3.7, z: 37.3 },
    wellOffset: { x: 4.5, y: 4.5 },
    wellStart: { x: 12.13, y: 8.5, z: 3.7 },
    wellShapeType: WellShapeEnum.CIRCLE,
    contentSource: ContentSourceType.USER_GENERATED,
  },
};

// Dummy plate used as a base to create default sized plates (by
// ovverriding its dimensions).
const DUMMY_PLATE_BASE: PlateType = {
  name: '96 wells',
  description: '',
  dimension: {
    x: 125,
    y: 85,
    z: 0,
  },
  accessory: 'none',
  catalogNumber: '',
  catalogUrl: '',
  color: '',
  type: 'customPlate',
  format: '96well',
  id: '',
  manufacturer: 'user-defined plate type',
  usage: 'electrophoresis',
  wellBottomOffset: 1,
  defaultResidualVolume: 1,
  editable: true,
  rows: 8,
  columns: 12,
  wellOffset: { x: 9, y: 9 },
  wellStart: { x: 13.78, y: 11.24, z: 3.7 },
  version: 1,
  wellShape: {
    type: WellShapeEnum.CIRCLE,
    bottomType: WellBottomShapeEnum.FLAT_BOTTOM,
    dimensionMm: { x: 7.1, y: 7.1, z: 10.2 },
    volumeOverrideUl: 500,
  },
  containerType: ContainerType.WELL,
  contentSource: ContentSourceType.USER_GENERATED,
};

type PlateDimensions = Pick<
  PlateType,
  'rows' | 'columns' | 'wellOffset' | 'wellStart'
> & { wellDimension: Position3d; wellShapeType: WellShapeEnum };

export const COMMON_PLATE_NAMES = [...Object.keys(COMMON_PLATE_DIMENSIONS)];

export type CommonPlateSizes = keyof typeof COMMON_PLATE_DIMENSIONS;

export function isCommonPlateSize(s: unknown): s is CommonPlateSizes {
  return typeof s === 'string' && COMMON_PLATE_NAMES.includes(s);
}

/**
 * Create a dummy, default sized, nice looking plate to render in the UI.
 *
 * @param plateSize One size out of the most common plate sizes used in labs.
 * @param wellShape Optional: Change the UI appearance of all wells.
 */
export default function createDummyPlate(plateSize: CommonPlateSizes): PlateType {
  const plateDimensions: PlateDimensions = COMMON_PLATE_DIMENSIONS[plateSize];

  return produce(DUMMY_PLATE_BASE, draft => {
    // Need to override these properties to correctly show the plate, the
    // dropdown and correctly select wells.
    draft.id = plateSize;
    draft.name = plateSize;
    draft.type = plateSize;

    if (plateDimensions.wellShapeType) {
      draft.wellShape.type = plateDimensions.wellShapeType;
    }

    const { columns, rows, wellOffset, wellDimension, wellStart } = plateDimensions;
    draft.columns = columns;
    draft.rows = rows;
    draft.wellOffset = wellOffset;
    draft.wellShape.dimensionMm = wellDimension;
    draft.wellStart = wellStart;
  });
}
