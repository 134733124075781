import { formatMeasurementObj } from 'common/lib/format';
import { isMeasurement, RuleConsequenceValue } from 'common/types/mix';

export default function formatRuleConsequenceValueRecursive(
  value: RuleConsequenceValue,
): string {
  if (typeof value === 'object' && 'kind' in value) {
    switch (value.kind) {
      case 'constant':
        return formatMeasurementObj(value.constant_volume);
      case 'maximum':
        return `max(${value.volumes
          .map(formatRuleConsequenceValueRecursive)
          .join(', ')})`;
      case 'minimum':
        return `min(${value.volumes
          .map(formatRuleConsequenceValueRecursive)
          .join(', ')})`;
      case 'property':
        return value.property;
      case 'scaled':
        return `${value.factor} x ${formatRuleConsequenceValueRecursive(
          value.volume_to_scale,
        )}`;
      default:
        throw new Error('Unknown kind of value');
    }
  } else if (isMeasurement(value)) {
    return formatMeasurementObj(value);
  } else {
    return String(value);
  }
}
