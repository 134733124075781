import React from 'react';

import { GraphQLErrors } from '@apollo/client/errors';
import Typography from '@mui/material/Typography';
import { GraphQLErrorExtensions } from 'graphql';

import { GET_TUTORIALS_TARGET_ENVIRONMENTS } from 'admin-client/app/api/gql/queries';
import { HasPermission } from 'admin-client/app/components/Permissions';
import { PublishContentUI } from 'admin-client/app/components/PublishContentUI';
import {
  PAGE_ROWS_DEFAULT,
  PublishHistoryUI,
} from 'admin-client/app/components/PublishHistoryUI';
import { usePublishTutorials } from 'admin-client/app/components/Tutorials/hooks';
import { Mutation, PublishTutorialsResultFailureEnvs } from 'admin-client/app/gql';
import { Stack } from 'common/ui/components/Stack';
import { isLocalDev } from 'common/ui/lib/envs';

export function TutorialsScreen() {
  const { onClick, loading } = usePublishTutorials();

  const pageState = React.useState(0);
  const pagePerRowsState = React.useState(PAGE_ROWS_DEFAULT);
  return (
    <div>
      <Typography variant="h2">Tutorials</Typography>
      <Stack spacing={8}>
        <div>
          <Typography variant="body1">
            We create tutorials which get pushed out to customer environments in order to:
            <ul>
              <li>help them learn how to use the platform more quickly</li>
              <li>have a high quality jumping off point for their own experiments</li>
              <li>show off newly released features of the platform</li>
            </ul>
          </Typography>
          <Typography variant="body1">
            Tutorials can be edited by logging in to{' '}
            <a href="https://synthace.synthace.bio">synthace.synthace.bio</a> with org
            &apos;examples&apos;.
          </Typography>
        </div>

        <HasPermission
          permission="push:examples"
          renderItem={hasPermission => {
            return hasPermission ? (
              <PublishContentUI
                title="Publish Tutorials"
                loading={loading}
                onClick={onClick}
                description={
                  <>
                    <Typography variant="body1">
                      Once you have made updates in the examples org you can push them out
                      to all environments using the button below.
                    </Typography>
                    <Typography variant="body1">
                      If a tutorial contains a workflow which uses elements that an org
                      does not have access to, it will be automatically excluded.
                    </Typography>
                  </>
                }
                getTargetEnvironmentsQuery={GET_TUTORIALS_TARGET_ENVIRONMENTS}
                mkOptions={(snackbarManager, setTargetEnvironments) => ({
                  onCompleted: ({
                    getTutorialsTargetEnvironments: { environmentURLs },
                  }) => {
                    setTargetEnvironments(environmentURLs.toSorted());
                  },
                  onError: _error => {
                    snackbarManager.showWarning(
                      "Couldn't fetch target environments. Please refresh the page to retry.",
                    );
                  },
                })}
              />
            ) : null;
          }}
        />

        <div>
          <Typography variant="h4">Publish Tutorials History</Typography>
          <PublishHistoryUI<string, PublishTutorialsResultFailureEnvs, undefined>
            actionName="publishTutorials"
            errorRenderer={renderTutorialsError}
            failureEnvRenderer={derivedFailureEnvs => {
              // Program defensively here because old actions might not have well-formed.
              return (
                <>
                  {derivedFailureEnvs?.map(({ envURL, message }, idx) => (
                    <div key={idx}>
                      {'- '}
                      <a href={envURL} target="_blank" rel="noopener noreferrer">
                        {envURL}
                      </a>
                      {` ${message}${idx < derivedFailureEnvs.length ? ', ' : ''}`}
                    </div>
                  ))}
                </>
              );
            }}
            actionItemMapper={({
              id,
              occurredAt,
              resultType,
              userEmail,
              responseBody,
            }) => {
              const successEnvs = (responseBody?.data as Mutation)?.['publishTutorials']
                .successEnvs;
              const failureEnvs = (responseBody?.data as Mutation)?.['publishTutorials']
                .failureEnvs;
              const errors = (responseBody?.errors as GraphQLErrors) || [];
              return {
                id,
                occurredAt,
                resultType,
                userEmail,
                errors,
                failureEnvs,
                successEnvs,
                extras: undefined,
              };
            }}
            successEnvRenderer={(successEnvs: string[]) => {
              // Program defensively here because old actions might not have well-formed.
              return (
                <>
                  {`Published successfully to ${(successEnvs ?? []).length} environment${
                    (successEnvs ?? []).length > 1 ? 's' : ''
                  }: `}
                  {successEnvs?.map((envURL, idx) => (
                    <>
                      <a
                        key={idx}
                        href={envURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {envURL}
                      </a>
                      {idx < successEnvs.length && successEnvs.length > 1 ? ', ' : ''}
                    </>
                  ))}
                </>
              );
            }}
            paginationState={{ pageState, pagePerRowsState }}
          />
        </div>
      </Stack>
    </div>
  );
}

function renderTutorialsError(
  _message: string,
  extensions: GraphQLErrorExtensions,
): React.ReactElement | null {
  function isSynthaceGraphQLError(
    extensions: unknown,
  ): extensions is { detail: { code: string; data: { id: string; message: string }[] } } {
    return (
      typeof extensions === 'object' &&
      extensions !== null &&
      'code' in extensions &&
      extensions.code === 'PUBLISH_TUTORIALS_FAILED' &&
      'detail' in extensions &&
      typeof extensions.detail === 'object' &&
      extensions.detail !== null &&
      'code' in extensions.detail &&
      extensions.detail.code !== null &&
      typeof extensions.detail.code === 'string' &&
      // We called these code in different ways at different times.
      // Since the data comes from events written to the database
      // (that's what the 'getAction' query returns) we need to recognise
      // all as legit codes.
      ['workflows', 'experiments', 'workflow', 'experiment'].includes(
        extensions.detail.code,
      ) &&
      'data' in extensions.detail &&
      Array.isArray(extensions.detail.data) &&
      extensions.detail.data.every(x => 'id' in x && 'message' in x)
    );
  }

  function getSourceEnvironmentURL() {
    return isLocalDev(window.location)
      ? `http://${window.location.hostname}:8800` // source environment default URL in local dev
      : `https://synthace.synthace.bio`;
  }

  if (isSynthaceGraphQLError(extensions)) {
    const mkHref = (id: string) =>
      'url' in extensions.detail
        ? (extensions.detail.url as string)
        : `${getSourceEnvironmentURL()}/#/${extensions.detail.code}/${id}`;

    return (
      <>
        {extensions.detail.data.map(({ message, id }) => {
          return (
            <div key={id}>
              {`- ${extensions.detail.code.replace(/^(.)/, (_, firstChar) =>
                firstChar.toUpperCase(),
              )} `}
              <a href={mkHref(id)} target="_blank" rel="noopener noreferrer">
                {id.slice(0, 8)}
              </a>
              : {message}
            </div>
          );
        })}
      </>
    );
  }

  return null;
}
