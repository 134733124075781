import { ConfigurationType } from 'common/types/commonConfiguration';
import { ElementConfigurationID, ElementName } from 'common/types/elementConfiguration';
import { TypeConfigurationID, TypeName } from 'common/types/typeConfiguration';
import { route } from 'common/ui/navigation';

export type EnvironmentParams = { hostname: string };

export type OrganizationParams = { humanIdentifier: string };

export type RolesParams = { humanIdentifier: string; userId: string };

type ElementListParams = {
  elementName: ElementName;
};

type ElementListWithQueryParams = {
  elementName: ElementName;
  commitBranch: string;
  commitHash: string;
  commitDate: string;
};

type ElementEditParams = {
  elementName: ElementName;
  id: ElementConfigurationID;
};

type ElementCreateParams = {
  elementName: ElementName;
  commitHash: string;
  commitBranch: string;
  configurationType: ConfigurationType;
};

type TypeEditParams = {
  typeName: TypeName;
  id: TypeConfigurationID;
};

type TypeCreateParams = {
  typeName: TypeName;
  commitHash: string;
  commitBranch: string;
  configurationType: ConfigurationType;
};

export type OrganizationEnvDeployParams = {
  envHostname: string;
  orgHumanIdentifier: string;
};

/**
 * All routes used for the admin tool.
 *
 * Usage:
 *
 * 1) For Route component:
 *
 *   <Route path={ROUTES.HOME.pathTemplate} />
 *
 * 2) For navigation:
 *
 *     history.push(ROUTES.HOME.getPath())
 *
 *   or
 *
 *     <Link to={ROUTES.HOME.getPath()}>Home</Link>
 */
export const ROUTES = {
  HOME: route<void>('/'),

  ANTHA_HUB: {
    METRICS: route<void>('/synthacehub/metrics'),
    RELEASES: route<void>('/synthacehub/releases'),
  },

  ENVIRONMENTS: {
    VIEW: route<EnvironmentParams>('/environments/:hostname'),
  },

  ORGANIZATIONS: {
    ROOT: route<void>('/organisations'),
    VIEW: route<OrganizationParams>('/organisations/:humanIdentifier'),
    ENV_DEPLOYMENT: route<OrganizationEnvDeployParams>(
      '/organisations/:orgHumanIdentifier/:envHostname',
    ),
  },

  ROLES: {
    VIEW: route<RolesParams>('/organisations/:humanIdentifier/users/:userId'),
  },

  ELEMENT_CONFIGURATION: {
    ROOT: route<void>('/elements'),

    LIST: route<ElementListParams>(`/elements/:elementName`),

    LIST_WITH_QUERY_PARAMS: route<ElementListWithQueryParams>(
      `/elements/:elementName/?commitBranch=:commitBranch&commitHash=:commitHash&commitDate=:commitDate`,
    ),

    EDIT: route<ElementEditParams>('/elements/:elementName/edit/:id'),

    CREATE: route<ElementCreateParams>(
      '/elements/:elementName/create/:commitHash/:commitBranch/:configurationType',
    ),
  },

  TYPE_CONFIGURATION: {
    ROOT: route<void>('/types'),

    LIST: route<{
      typeName: TypeName;
    }>(`/types/:typeName`),

    EDIT: route<TypeEditParams>('/types/:typeName/edit/:id'),

    CREATE: route<TypeCreateParams>(
      '/types/:typeName/create/:commitHash/:commitBranch/:configurationType',
    ),
  },

  FEATURETOGGLES: {
    ROOT: route<void>('/featureToggles'),
  },

  PLATE_TYPES: {
    ROOT: route<void>('/plateTypes'),
  },

  REDIRECTINATOR: route<void>('/redirectinator'),

  PERMISSIONS: route<void>('/permissions'),

  MIGRATIONS: route<void>('/migrations'),

  TUTORIALS: route<void>('/tutorials'),

  EXAMPLE_WORKFLOWS: route<void>('/exampleWorkflows'),
};
