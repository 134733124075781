import React, { useState } from 'react';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import WavesIcon from '@mui/icons-material/Waves';
import Autocomplete from '@mui/material/Autocomplete';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from 'uuid';

import {
  useLiquidIdentifierOption,
  useLiquidIdentifierPathSelection,
} from 'client/app/components/Parameters/PlateLayout/liquidIdentifierUtils';
import { LiquidNameOrGroupName } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { pluralize } from 'common/lib/format';
import { LiquidAssignment } from 'common/types/plateAssignments';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import LiquidsIcon from 'common/ui/icons/LiquidsIcon';

type Props = {
  options: LiquidNameOrGroupName[];
  value: LiquidAssignment;
  isDisabled: boolean;
  onChange: (value: LiquidNameOrGroupName | undefined) => void;
};

function isMatch(filter: string, option: LiquidNameOrGroupName): boolean {
  return (
    (option.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
      option.contents?.some(o => isMatch(filter, o))) ??
    false
  );
}

export default function LiquidIdentifierSelect({
  value,
  options,
  isDisabled,
  onChange,
}: Props) {
  const [liquidIdentifierError, setLiquidIdentifierError] = useState<string | undefined>(
    undefined,
  );

  const handleSetLiquidIdentifier = (newValue: string | undefined) => {
    if (newValue === '' || newValue === undefined) {
      setLiquidIdentifierError('Liquid identifier cannot be empty');
      return;
    }

    setLiquidIdentifierError(undefined);
    const existingValue = options.find(nameOrGroup => nameOrGroup.name === newValue);
    onChange(existingValue ?? { id: uuid(), name: newValue, type: 'name' });
  };

  const { path, addToPath, removeFromPath } = useLiquidIdentifierPathSelection();

  const { parent, visibleOptions } = useLiquidIdentifierOption(options, path);

  return (
    <>
      <Autocomplete
        value={value.liquidName ?? value.liquidGroup}
        freeSolo
        size="small"
        options={visibleOptions}
        onChange={(_e, value) => {
          if (value && typeof value !== 'string') {
            handleSetLiquidIdentifier(value.name);
          } else {
            handleSetLiquidIdentifier(value ?? undefined);
          }
        }}
        disabled={isDisabled}
        filterOptions={(options, state) => {
          return options.filter(
            option =>
              option === null || option === parent || isMatch(state.inputValue, option),
          );
        }}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option?.name ?? ''
        }
        ListboxComponent={Listbox}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Liquid or Tag name..."
            InputProps={{
              ...params.InputProps,
              startAdornment: value.liquidName ? (
                <LiquidsIcon />
              ) : value.liquidGroup ? (
                <WavesIcon />
              ) : null,
            }}
          />
        )}
        renderOption={(props, option) => {
          return option === parent ? (
            <StyledOptionListHeader
              {...props}
              key={option.id}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                removeFromPath();
              }}
            >
              <ChevronLeft />
              <TypographyWithTooltip variant="caption" component="span">
                {option.type === 'group' ? 'Liquids in ' : 'Sub-components of '}
                <b>{option.name}</b>
              </TypographyWithTooltip>
            </StyledOptionListHeader>
          ) : (
            <StyledOptionListItem {...props} key={option.id}>
              <ItemNameAndType>
                {option.type === 'name' ? <LiquidsIcon /> : <WavesIcon />}
                <TypographyWithTooltip>{option.name}</TypographyWithTooltip>
              </ItemNameAndType>
              {option.contents && option.contents.length > 0 && (
                <Tooltip
                  title={pluralize(
                    option.contents.length,
                    option.type === 'group' ? 'Liquid' : 'Sub-component',
                  )}
                  placement="right"
                >
                  <ExpandItemButton
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      addToPath(option.id);
                    }}
                  >
                    <span>{option.contents.length}</span>
                    <ChevronRight />
                  </ExpandItemButton>
                </Tooltip>
              )}
            </StyledOptionListItem>
          );
        }}
      />
      <ErrorText variant="caption">{liquidIdentifierError}</ErrorText>
    </>
  );
}
const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const StyledOptionListItem = styled('li')({
  padding: 0,

  '&.MuiAutocomplete-option': {
    padding: '0',
    alignItems: 'stretch',
  },
});

const StyledOptionListHeader = styled(StyledOptionListItem)(({ theme }) => ({
  '&.MuiAutocomplete-option': {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 4),
    alignItems: 'center',
    borderBottom: `1px solid ${Colors.GREY_20}`,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
}));

const ExpandItemButton = styled('button')(({ theme }) => ({
  flexShrink: 0,
  color: theme.palette.text.secondary,
  marginLeft: 'auto',
  ...theme.typography.body1,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3, 0, 4),
  gap: theme.spacing(2),
  background: 'transparent',
  cursor: 'pointer',
  border: '0',
  '&:hover': {
    background: theme.palette.grey[100],
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
}));

const ItemNameAndType = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  padding: theme.spacing(3, 4),
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
  overflow: 'hidden',
}));

const Listbox = styled(List)({
  '&.MuiAutocomplete-listbox': {
    padding: 0,
  },
});
