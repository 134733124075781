import React, { useCallback } from 'react';

import { MutationHookOptions, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

import { DELETE_ENVIRONMENT } from 'admin-client/app/api/gql/queries';
import { HasPermission } from 'admin-client/app/components/Permissions';
import { getEnvironmentQuery } from 'admin-client/app/gql';
import { ROUTES } from 'admin-common/src/routing/routes';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import useDialog from 'common/ui/hooks/useDialog';
import { cacheEvict } from 'common/utils';

type OrgActionProps = {
  environment: getEnvironmentQuery['environment'];
  mutationOptions?: MutationHookOptions;
};

export const DeleteEnvironment = ({ environment, mutationOptions }: OrgActionProps) => {
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const history = useHistory();
  const { showSuccess } = useSnackbarManager();

  const [deleteEnvironment, { loading }] = useMutation(DELETE_ENVIRONMENT, {
    ...mutationOptions,
    onCompleted: () => {
      showSuccess(`Successfully deleted environment ${environment.hostname}`);
      history.push(ROUTES.ORGANIZATIONS.ROOT.getPath());
    },
  });

  const onClick = useCallback(async () => {
    const shouldDelete = await openConfirmationDialog({
      isActionDestructive: true,
      action: 'delete',
      object: `${environment.hostname}`,
      omitThe: true,
      additionalMessage: `This will also delete the organisationEnvironments enabled on this environment:\n ${environment.enabledOrganisationEnvironments
        .map(
          orgEnv => `- ${orgEnv.organisation.humanIdentifier} on ${environment.hostname}`,
        )
        .join('\n')}`,
    });
    if (shouldDelete) {
      await deleteEnvironment({
        ...mutationOptions,
        variables: {
          environmentId: environment.id,
        },
        update: cache => cacheEvict(environment, cache),
      });
    }
  }, [deleteEnvironment, environment, mutationOptions, openConfirmationDialog]);

  if (loading) {
    // TODO (SYN-3019): replace this with MUI LoadingButton once we upgrade to MUI v5 (SYN-2425)
    return (
      <div>
        <Typography variant="body1">Deleting environment </Typography>{' '}
        <CircularProgress />
      </div>
    );
  }

  return (
    <HasPermission
      permission="delete:environments"
      renderItem={hasPermission => {
        return hasPermission ? (
          <div>
            {confirmationDialog}
            <Button variant="contained" color="secondary" onClick={onClick}>
              DELETE
            </Button>
          </div>
        ) : (
          <Typography variant="caption">
            Contact Matrix if you need to delete this environment
          </Typography>
        );
      }}
    />
  );
};
