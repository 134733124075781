import React, { useCallback, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import cx from 'classnames';

import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';

const TYPING_DEBOUNCE_MS = 200;

export type SearchFieldLogProps = {
  logAction: string;
  logCategory: string;
};

type Props = {
  autoFocus?: boolean;
  className?: string;
  addMargin?: boolean;
  debounceMs?: number;
  // Used to restore a value in the TextField.
  defaultValue?: string;
  fullWidth?: boolean;
  label?: string;
  variant?: TextFieldProps['variant'];
  placeholder?: string;
  searchFieldLogProps?: SearchFieldLogProps;
  onQueryChange: (query: string) => void;
  margin?: TextFieldProps['margin'];
};

export default function SearchField(props: Props) {
  const {
    addMargin,
    autoFocus,
    debounceMs = TYPING_DEBOUNCE_MS,
    defaultValue,
    fullWidth,
    label,
    variant = 'standard',
    placeholder,
    searchFieldLogProps,
    onQueryChange,
    margin,
  } = props;
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const [hasLogged, setHasLogged] = useState(false);

  const shouldLogSearch = useCallback(
    (query: string) => {
      return query.length > 1 && isFocused && !hasLogged;
    },
    [hasLogged, isFocused],
  );

  const handleQueryChange = useDebounce((query: string) => {
    onQueryChange(query);
    if (searchFieldLogProps && shouldLogSearch(query)) {
      logEvent(searchFieldLogProps.logAction, searchFieldLogProps.logCategory);
      setHasLogged(true);
    }
  }, debounceMs);

  const handleTypeInSearchbar = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => handleQueryChange(e.target.value),
    [handleQueryChange],
  );

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
    setHasLogged(false);
  }, []);

  return (
    <div className={cx(props.className, { [classes.margin]: addMargin })}>
      <TextField
        label={label}
        variant={variant}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type="search"
        onChange={handleTypeInSearchbar}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        margin={margin}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  margin: {
    margin: theme.spacing(6, 0, 5, 0),
  },
}));
