import React, { useCallback, useState } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';

import {
  ADD_USER_TO_ORG,
  BLOCK_USER_IN_ORG,
  DISABLE_ORG_IN_ENV,
  ENABLE_ORG_IN_ENV,
  GET_AUTH0_CONNECTIONS,
  GET_AVAILABLE_ROLES,
  GET_ENVIRONMENTS,
  GET_ORGANISATION,
  RENAME_ORGANISATION,
  SET_ORGANISATION_NOTES,
  UNBLOCK_USER_IN_ORG,
} from 'admin-client/app/api/gql/queries';
import { Connections } from 'admin-client/app/components/Connections/Connections';
import { EnvironmentLink } from 'admin-client/app/components/Environments/EnvironmentLink';
import { NotesPanel } from 'admin-client/app/components/Notes/NotesPanel';
import { OrganisationEnvironmentLink } from 'admin-client/app/components/OrganisationEnvironments/OrganisationEnvironmentLink';
import { DeleteOrganisation } from 'admin-client/app/components/Organisations/DeleteOrganisation';
import { NewUserDialog } from 'admin-client/app/components/Organisations/NewUserDialog';
import { OrganisationUsersTable } from 'admin-client/app/components/Organisations/OrganisationUsersTable';
import { RenameOrganisationDialog } from 'admin-client/app/components/Organisations/RenameOrganisationDialog';
import { HasPermission, usePermissions } from 'admin-client/app/components/Permissions';
import {
  ArrayElement,
  getAuth0ConnectionsQuery,
  getOrganisationQuery,
} from 'admin-client/app/gql';
import { OrganizationParams } from 'admin-common/src/routing/routes';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { useDialogManager } from 'common/ui/components/DialogManager';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { Stack } from 'common/ui/components/Stack';
import { Role } from 'common/ui/components/UserRole';
import useDialog from 'common/ui/hooks/useDialog';

export default function OrganisationDetailsContainer() {
  const { humanIdentifier } = useParams<OrganizationParams>();

  const org = useQuery(GET_ORGANISATION, {
    variables: { humanIdentifier },
  });
  const envs = useQuery(GET_ENVIRONMENTS);
  const connections = useQuery(GET_AUTH0_CONNECTIONS);
  const roles = useQuery(GET_AVAILABLE_ROLES);

  if (org.loading || envs.loading || connections.loading || roles.loading) {
    return <div>Loading...</div>;
  }
  const error = org.error ?? envs.error ?? connections.error ?? roles.error;
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  if (!roles.data?.availableRoles?.customerRoles) {
    return <div>Error! No role data available</div>;
  }

  if (!org.data) {
    return <div>Error! No organisation data available</div>;
  }
  if (!envs.data) {
    return <div>Error! No environments data available</div>;
  }

  return (
    <OrganisationDetails
      org={org.data.organisation}
      envs={envs.data.environments}
      connections={connections.data ? [...connections.data.auth0Connections] : []}
      roles={roles.data.availableRoles.customerRoles}
    />
  );
}

type GraphQLConnection = ArrayElement<getAuth0ConnectionsQuery['auth0Connections']>;
type GraphQLOrganisation = getOrganisationQuery['organisation'];
type GraphQLOrganisationEnvironment = ArrayElement<
  getOrganisationQuery['organisation']['environmentConfigs']
>['environment'];

type OrganisationDetailsProps = {
  org: GraphQLOrganisation;
  envs: GraphQLOrganisationEnvironment[];
  connections: GraphQLConnection[];
  roles: readonly Role[];
};

const OrganisationDetails = React.memo((props: OrganisationDetailsProps) => {
  const { org, envs, connections, roles } = props;
  const classes = useStyles();
  const snackbarManager = useSnackbarManager();
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const [isEditingEnvironments, setIsEditingEnvironments] = useState(false);
  const { currentUserHasPermission } = usePermissions();

  const commonMutationOptions = {
    onError: (error: ApolloError) => {
      console.error(error);
      snackbarManager.showError(error.message);
    },
  };

  const [enableOrgMutation] = useMutation(ENABLE_ORG_IN_ENV, commonMutationOptions);
  const [disableOrgMutation] = useMutation(DISABLE_ORG_IN_ENV, commonMutationOptions);
  const [addUserMutation] = useMutation(ADD_USER_TO_ORG, commonMutationOptions);
  const [blockUserMutation] = useMutation(BLOCK_USER_IN_ORG, commonMutationOptions);
  const [unblockUserMutation] = useMutation(UNBLOCK_USER_IN_ORG, commonMutationOptions);
  const [renameOrganisationMutation] = useMutation(
    RENAME_ORGANISATION,
    commonMutationOptions,
  );
  const [setNotesMutation] = useMutation(SET_ORGANISATION_NOTES, commonMutationOptions);
  const dialogManager = useDialogManager();

  const enabledInEnv = (env: GraphQLOrganisationEnvironment): boolean => {
    if (!org.environmentConfigs || org.environmentConfigs.length === 0) {
      return false;
    }
    for (const orgEnv of org.environmentConfigs) {
      if (orgEnv.environment.id === env.id) {
        return orgEnv.isEnabled;
      }
    }
    return false;
  };

  const handleChange = (envId: string) => async () => {
    const environment = envs.filter(env => env.id === envId)[0];
    const isEnabling = !enabledInEnv(environment);
    const shouldToggle = await openConfirmationDialog({
      action: isEnabling ? 'enable' : 'disable',
      object: `${org.name} in ${environment.hostname}`,
      additionalMessage: isEnabling
        ? `Users from ${org.name} will be able to log in at ${environment.hostname}`
        : `Users from ${org.name} will no longer be able to log in at ${environment.hostname}`,
    });
    if (shouldToggle) {
      const mutationArgs = {
        variables: {
          orgId: org.id,
          envId: envId,
        },
        refetchQueries: [
          {
            query: GET_ORGANISATION,
            variables: { humanIdentifier: org.humanIdentifier },
          },
        ],
      };

      // TODO: use optimistic UI here?
      // https://www.apollographql.com/docs/react/performance/optimistic-ui/
      if (isEnabling) {
        await enableOrgMutation(mutationArgs);
      } else {
        await disableOrgMutation(mutationArgs);
      }
    }
  };

  const handleAddUserButtonClick = async () => {
    const newUser = await dialogManager.openDialogPromise('ADD_USER', NewUserDialog, {
      org,
      roles,
    });
    if (newUser !== null) {
      // TODO: handle errors when processing the mutation, e.g. when the email
      // address already exists
      await addUserMutation({
        variables: {
          ...newUser,
          orgId: org.id,
        },
        refetchQueries: [
          {
            query: GET_ORGANISATION,
            variables: { humanIdentifier: org.humanIdentifier },
          },
        ],
      });
    }
  };

  const handleRenameOrganisationClick = useCallback(async () => {
    const newName = await dialogManager.openDialogPromise(
      'RENAME_ORGANISATION',
      RenameOrganisationDialog,
      {
        org,
      },
    );
    if (newName !== null && newName.length > 0 && newName !== org.name) {
      await renameOrganisationMutation({
        variables: {
          organisationId: org.id,
          newName,
        },
        refetchQueries: [
          {
            query: GET_ORGANISATION,
            variables: { humanIdentifier: org.humanIdentifier },
          },
        ],
      });
    }
  }, [dialogManager, org, renameOrganisationMutation]);

  type GraphQLOrganisationUser = ArrayElement<
    getOrganisationQuery['organisation']['users']
  >;
  // getOrganisation_organisation_users as GraphQLOrganisationUser,

  const handleToggleUserBlockedStatus = useCallback(
    async (user: GraphQLOrganisationUser) => {
      const isCurrentlyBlocked = user.blocked;
      const actionText = isCurrentlyBlocked ? 'unblock' : 'block';
      const shouldProceed = await openConfirmationDialog({
        action: `${actionText}`,
        object: 'user',
        additionalMessage: `${user.givenName} ${user.familyName} will be ${actionText}ed.`,
      });
      if (shouldProceed) {
        const mutation = isCurrentlyBlocked ? unblockUserMutation : blockUserMutation;
        await mutation({
          variables: {
            email: user.email,
            orgId: org.id,
          },
          refetchQueries: [
            {
              query: GET_ORGANISATION,
              variables: { humanIdentifier: org.humanIdentifier },
            },
          ],
        });
      }
    },
    [
      blockUserMutation,
      openConfirmationDialog,
      org.humanIdentifier,
      org.id,
      unblockUserMutation,
    ],
  );

  const enableEnvironmentEditMode = useCallback(() => {
    setIsEditingEnvironments(true);
  }, []);

  const handleNotesUpdate = useCallback(
    async (notes: string) => {
      await setNotesMutation({
        variables: {
          organisationId: org.id,
          notes,
        },
        refetchQueries: [
          {
            query: GET_ORGANISATION,
            variables: { humanIdentifier: org.humanIdentifier },
          },
        ],
      });
    },
    [org.humanIdentifier, org.id, setNotesMutation],
  );

  return (
    <>
      <section className={classes.section}>
        <Stack spacing={3}>
          <div>
            <Typography variant="h2">{org.name}</Typography>
            <Typography variant="subtitle2" className={classes.orgId}>
              {org.id}
            </Typography>
          </div>
          <NotesPanel
            notes={org.notes}
            onUpdate={handleNotesUpdate}
            editable={currentUserHasPermission('update:organisations')}
          />
        </Stack>
      </section>
      <section className={classes.section}>
        <div className={classes.sectionHeader}>
          <Typography variant="h4" gutterBottom>
            Environments
          </Typography>
        </div>
        {isEditingEnvironments ? (
          <FormControl>
            <FormGroup>
              {envs.map(env => {
                return (
                  <FormControlLabel
                    key={env.id}
                    value={env.id}
                    control={
                      <Checkbox
                        onChange={handleChange(env.id)}
                        checked={enabledInEnv(env)}
                      />
                    }
                    label={env.hostname}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        ) : (
          <>
            <ul>
              {envs
                .filter(env => enabledInEnv(env))
                .map(env => {
                  return (
                    <li key={env.id}>
                      <Typography>
                        <EnvironmentLink env={env} /> (
                        <OrganisationEnvironmentLink org={org} env={env} />)
                      </Typography>
                    </li>
                  );
                })}
            </ul>
            <HasPermission
              permission="update:organisations"
              renderItem={hasPermission => {
                return hasPermission ? (
                  <Button variant="contained" onClick={enableEnvironmentEditMode}>
                    Change Environments
                  </Button>
                ) : null;
              }}
            />
          </>
        )}
      </section>
      <HasPermission
        permission="update:connections"
        renderItem={hasPermission => {
          return hasPermission ? (
            <section className={classes.section}>
              <div className={classes.sectionHeader}>
                <Typography variant="h4" gutterBottom>
                  Connections
                </Typography>
              </div>
              <Connections
                connections={connections}
                org={{ ...org, id: org.id as UUID }}
                mutationOptions={commonMutationOptions}
              />
            </section>
          ) : null;
        }}
      />
      <HasPermission
        permission="read:users"
        renderItem={hasPermission => {
          return hasPermission ? (
            <section className={classes.section}>
              <div className={classes.sectionHeader}>
                <Typography variant="h4" gutterBottom>
                  Users
                </Typography>
                <HasPermission
                  permission="create:users"
                  renderItem={hasPermission => {
                    return hasPermission ? (
                      <>
                        <Box className={classes.addUserButtonContainer}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleAddUserButtonClick}
                            disabled={!org.hasUserDatabase}
                          >
                            <AddIcon className={classes.addIcon} />
                            Add User
                          </Button>
                          {org.hasUserDatabase || (
                            <Typography variant="caption" component="div">
                              This organisation maintains their own users via SSO
                            </Typography>
                          )}
                        </Box>
                        {confirmationDialog}
                      </>
                    ) : null;
                  }}
                />
              </div>
              <OrganisationUsersTable
                users={org.users}
                toggleUserBlockedStatusHandler={handleToggleUserBlockedStatus}
              />
            </section>
          ) : null;
        }}
      />
      <HasPermission
        permission="update:organisations"
        renderItem={hasPermission => {
          return hasPermission ? (
            <section>
              <Typography variant="h4" gutterBottom>
                Actions
              </Typography>
              <Stack spacing={4}>
                <Button variant="contained" onClick={handleRenameOrganisationClick}>
                  Rename organisation
                </Button>
                <DeleteOrganisation org={org} mutationOptions={commonMutationOptions} />
              </Stack>
            </section>
          ) : null;
        }}
      />
    </>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      paddingBottom: '2rem',
    },
    addUserButtonContainer: {
      textAlign: 'right',
    },
    orgId: {
      color: theme.palette.text.secondary,
    },
    addIcon: {
      marginRight: '1rem',
    },
    sectionHeader: {
      display: 'grid',
      gridTemplateColumns: '4fr 1fr',
    },
  }),
);
