import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';

import { GET_ONLINE_MIGRATION_ADMIN_STATES } from 'admin-client/app/api/gql/queries';
import { OnlineMigrationStatusChip } from 'admin-client/app/components/OnlineMigrations/OnlineMigrationStatusChip';
import { ArrayElement, getOnlineMigrationAdminStatesQuery } from 'admin-client/app/gql';
import { orderedOnlineMigrationStages } from 'common/types/onlineMigration';
import Colors from 'common/ui/Colors';

export default function OnlineMigrationDetailsContainer() {
  const { migrationName } = useParams<{ migrationName: string }>();
  const { loading, error, data } = useQuery(GET_ONLINE_MIGRATION_ADMIN_STATES);
  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }
  return (
    <OnlineMigrationDetails
      environments={data?.environments ?? []}
      migrationName={migrationName}
    />
  );
}

type OnlineMigrationDetailsProps = {
  environments: getOnlineMigrationAdminStatesQuery['environments'];
  migrationName: string;
};

const OnlineMigrationDetails = React.memo(function OnlineMigrationDetails(
  props: OnlineMigrationDetailsProps,
) {
  const { environments, migrationName } = props;
  const migrationPerEnvironment = useMemo(() => {
    const result: {
      [key: string]: OnlineMigrationAdminState;
    } = {};
    for (const env of environments) {
      for (const migration of env.onlineMigrationAdminStates) {
        if (migration.name === migrationName) {
          result[env.id] = migration;
        }
      }
    }
    return result;
  }, [environments, migrationName]);
  return (
    <div>
      <Typography variant="h2" gutterBottom>
        {migrationName}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {orderedOnlineMigrationStages.map(stage => (
              <TableCell key={stage} align="center">
                <Typography>{stage}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {environments.map(env => (
            <OnlineMigrationDetailsRow
              key={env.id}
              environment={env}
              migration={migrationPerEnvironment[env.id]}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
});

type OnlineMigrationAdminState = ArrayElement<
  ArrayElement<
    getOnlineMigrationAdminStatesQuery['environments']
  >['onlineMigrationAdminStates']
>;

type Environment = ArrayElement<getOnlineMigrationAdminStatesQuery['environments']>;

type OnlineMigrationDetailsRowProps = {
  environment: Environment;
  migration?: OnlineMigrationAdminState;
};

const OnlineMigrationDetailsRow = React.memo(function OnlineMigrationDetailsRow(
  props: OnlineMigrationDetailsRowProps,
) {
  const { environment, migration } = props;
  const classes = useStyles();

  const getChip = useCallback((migration: OnlineMigrationAdminState) => {
    const label =
      migration.codeVersion === migration.backfillVersionCompleted
        ? `Up to date`
        : migration.codeVersion === migration.backfillVersionProgress
        ? `In progress`
        : `Queued`;

    return (
      <OnlineMigrationStatusChip
        label={label}
        overridden={!!migration?.stageOverride}
        backfillProgress={
          // String comparison here is type-safe because `label` is of a
          // literal union type
          label === 'In progress' ? backfillProgressStr(migration) : undefined
        }
      />
    );
  }, []);

  return (
    <TableRow>
      <TableCell>
        <Typography>{environment.hostname}</Typography>
        {migration ? (
          <Typography className={classes.codeVersion} variant="subtitle2">
            Code version: {migration.codeVersion}
          </Typography>
        ) : (
          <Typography className={classes.codeVersion} variant="subtitle2">
            Not deployed to this environment
          </Typography>
        )}
      </TableCell>
      {orderedOnlineMigrationStages.map(s => (
        <TableCell key={s} align="center">
          {migration?.stage === s && getChip(migration)}
        </TableCell>
      ))}
    </TableRow>
  );
});

function backfillProgressStr(migration: OnlineMigrationAdminState) {
  const num = migration.backfillEntitiesWritten;
  // If the number of entities in the old store is known, include %
  const denom = migration.entityCount;
  return `Backfill progress: ${num} entities${
    denom ? ` (${Math.floor((100 * num) / denom)}% of ${denom}` : ''
  }`;
}

const useStyles = makeStyles({
  codeVersion: {
    color: Colors.GREY_40,
  },
});
