import React from 'react';

import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useParams } from 'react-router-dom';

import {
  GET_AVAILABLE_ROLES,
  GET_USER,
  GET_USER_ROLES,
} from 'admin-client/app/api/gql/queries';
import { UserRoleTable } from 'admin-client/app/components/Roles/UserRoleTable';
import {
  getAvailableRolesQuery as GraphQLAvailableRoles,
  getUserQuery as GraphQLUser,
  getUserRolesQuery as GraphQLRoles,
} from 'admin-client/app/gql';
import { RolesParams, ROUTES } from 'admin-common/src/routing/routes';
import { linkNoStyle } from 'common/ui/commonStyles';
import { Stack } from 'common/ui/components/Stack';
import { UserInitialsIcon } from 'common/ui/icons/UserInitialsIcon';

export default function UserDetailsContainer() {
  const { humanIdentifier, userId } = useParams<RolesParams>();
  const roles = useQuery(GET_USER_ROLES, {
    variables: { id: userId },
  });
  const user = useQuery(GET_USER, { variables: { id: userId } });
  const availableRoles = useQuery(GET_AVAILABLE_ROLES);

  if (roles.loading || user.loading || availableRoles.loading) {
    return (
      <Typography variant="h6">
        <div>Loading...</div>
      </Typography>
    );
  }

  const error = user.error ?? roles.error ?? availableRoles.error;
  if (error) {
    return (
      <div>
        <Typography variant="h6">Error! {error.message}</Typography>
      </div>
    );
  }

  if (roles.data?.userRole === undefined) {
    return (
      <div>
        <Typography variant="h6">
          No roles found for user: {userId}. Please contact Matrix team.
        </Typography>
      </div>
    );
  }

  if (user.data === undefined) {
    return (
      <div>
        <Typography variant="h6">
          Could not find user: ${userId}. Please contact Matrix team.
        </Typography>
      </div>
    );
  }

  if (!availableRoles.data) {
    return (
      <div>
        <Typography variant="h6">
          No available roles found for user: {userId}. Please contact Matrix team.
        </Typography>
      </div>
    );
  }

  return (
    <UserDetails
      roles={roles.data}
      humanIdentifier={humanIdentifier}
      user={user.data}
      availableRoles={availableRoles.data}
    />
  );
}

type UserDetailsProps = {
  roles: GraphQLRoles;
  humanIdentifier: string;
  user: GraphQLUser;
  availableRoles: GraphQLAvailableRoles;
};

export const UserDetails = React.memo((props: UserDetailsProps) => {
  const { roles, humanIdentifier, user, availableRoles } = props;
  const classes = useStyles();

  const returnPath = ROUTES.ORGANIZATIONS.VIEW.getPath({
    humanIdentifier: humanIdentifier,
  });
  const userDisplayName = [user.user?.givenName, user.user?.familyName]
    .join(' ')
    .toUpperCase();

  return (
    <Container maxWidth="md">
      <Stack spacing={7}>
        <div className={classes.roleContainer}>
          <UserInitialsIcon size="large" userDisplayName={userDisplayName} />
          <div className={classes.userDetails}>
            <Typography variant="subtitle2">{userDisplayName}</Typography>
            <Typography variant="subtitle2">{user.user?.email}</Typography>
            <Typography variant="subtitle2">Organisation: {humanIdentifier}</Typography>
          </div>
        </div>
        <Grid className={classes.roleContainer}>
          <div>
            <Typography variant="subtitle2">Internal roles assigned to user:</Typography>
          </div>
          <UserRoleTable
            user={user}
            roles={roles.userRole.internalRoles}
            availableRoles={availableRoles.availableRoles.internalRoles}
            isEditable={false}
          />
          <br />
          <Typography variant="subtitle2">Customer roles assigned to user:</Typography>
          <UserRoleTable
            user={user}
            roles={roles.userRole.customerRole ? [roles.userRole.customerRole] : []}
            availableRoles={availableRoles.availableRoles.customerRoles}
            isEditable
          />
        </Grid>
        <div className={classes.button}>
          <Link to={returnPath} className={classes.link}>
            <Button variant="contained">Return to {humanIdentifier}</Button>
          </Link>
        </div>
      </Stack>
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    roleContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    userDetails: {
      textAlign: 'center',
      marginTop: theme.spacing(3),
    },
    link: linkNoStyle.style,
    button: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  }),
);
